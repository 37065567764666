import { useEffect, useState } from "react";
import {
  formattedDate,
  formattedDayTime,
  getFileUrl,
} from "../../utils/utilityFunctions";
import styles from "./RCNavbar.module.css";
import IncuHubLogo from "../../assets/png/IncuHubLogo.png";
import CalendarGIF from "../../assets/gif/Calendar.gif";
import moment from "moment";

const RCNavbar = () => {
  const profImage = sessionStorage.getItem("profPicture");
  const [navBarData, setNavBarData] = useState(null);
  const adminImage = localStorage.getItem("adminImage");
  const [dateTime, setDateTime] = useState({
    formattedDate: moment().format("D-M-YYYY"),
    formattedDayTime: `${moment().format("ddd")} - ${moment().format(
      "h:mm A"
    )}`,
  });

  useEffect(() => {
    const timer = setInterval(() => {
      setDateTime({
        formattedDate: moment().format("D-M-YYYY"),
        formattedDayTime: `${moment().format("ddd")} - ${moment().format(
          "h:mm A"
        )}`,
      });
    }, 60000); // every minute

    // Cleanup interval on component unmount
    return () => clearInterval(timer);
  }, []);

  const getUser = async () => {
    const token = localStorage.getItem("accessToken");
    try {
      const response = await fetch(`${window.env_url}/v1/users`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setNavBarData(data.body[0]);
    } catch (error) {
      console.error(error.message);
    }
  };

  let imgPath;
  if (profImage?.includes("avataaars.io")) {
    imgPath = profImage;
  } else {
    imgPath = getFileUrl + navBarData?.image;
  }

  let appLogo;
  if (adminImage === "undefined") {
    appLogo = IncuHubLogo;
  } else {
    appLogo = getFileUrl + localStorage.getItem("adminImage");
  }

  return (
    <div className={styles.rectangleDiv}>
      <div className={styles.rightSide}>
        <img className={styles.frameIcon} alt="" src={appLogo} />
        <p className={styles.incubator}>incubator</p>
      </div>
      <div className={styles.left}>
        <div className={styles.gifIcon}>
          <img src={CalendarGIF} alt="CalendarGIF" className={styles.gif} />
        </div>
        <div className={styles.today}>
          <span>{dateTime.formattedDate}</span>
          <span>{dateTime.formattedDayTime}</span>
        </div>
      </div>
    </div>
  );
};

export default RCNavbar;
