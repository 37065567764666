import React, { useEffect, useState } from "react";
import "./ProfileForm.css";
import ProfileFormImg from "../../../src/assets/png/ProfileFormImg.png";
import ProfileSelectionIcon from "../../../src/assets/svg/profilecamera.svg";
import { useQuery } from "react-query";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import PhoneNumberInput from "../../components/PhoneNumberInput";
import { toast } from "react-toastify";
import { useMembership } from "../../context/membershipContext";
const apiURL = window.env_url;
//console.log(apiURL);

function ProfileForm({ onClose, closeProfile, refetch }) {
  const fetchUserDetails = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      const { data } = await axios.get(`${window.env_url}/v1/users`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const userData = data.body[0];
      const imgData = userData.image;
      if (imgData.includes("avataaars.io")) {
        userData.imageUrl = imgData;
      } else {
        const imageResponse = await axios.get(
          `${window.env_url}/v1/files/${userData.image}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            responseType: "arraybuffer",
          }
        );

        const blob = new Blob([imageResponse.data], { type: "image/jpeg" });
        const url = URL.createObjectURL(blob);
        userData.imageUrl = url;
      }

      return userData;
    } catch (error) {
      console.log(error);
    }
  };

  const [imagePath, setImagePath] = useState(null);
    const { currentMembership, setCurrentMembership } = useMembership();
  

  const [userData, setUserData] = useState({});
  const [tempFlag, setTempFlag] = useState(true);
  const [profileImage, setProfileImage] = useState(ProfileFormImg);
  const { data, isLoading } = useQuery("userData", fetchUserDetails, {
    onSuccess: (data) => {
      if (data) {
        setUserData(data);
        //console.log(data);

        if (tempFlag && data.imageUrl) {
          setTempFlag(false);
          setProfileImage(data.imageUrl);
        }
      }
    },
  });

  const [selectedFile, setSelectedFile] = useState(null);

  console.log("userData", userData);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage(reader.result);
        setSelectedFile(file);
        //console.log("file", file);
        //console.log("Selected", selectedFile);
        updatePicture(file); // Pass file to updatePicture
      };
      reader.readAsDataURL(file);
    }

    //uplaod image api
  };

  const updatePicture = async (file) => {
    try {
      const token = localStorage.getItem("accessToken");
      //console.log("file", file);
      //should hapen before submission
      if (file) {
        const imageFormData = new FormData();
        //console.log(imageFormData)
        imageFormData.append("file", file);
        const imageUploadResponse = await axios.post(
          `${window.env_url}/v1/files`,
          imageFormData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        //console.log(imageUploadResponse)

        if (imageUploadResponse.status !== 201) {
          throw new Error(`HTTP Status error! ${imageUploadResponse.status}`);
        } else {
          //console.log(imageUploadResponse.data.body[0].filename)
          setImagePath(imageUploadResponse.data.body[0].filename);
          // imagePath = imageUploadResponse.data.body[0].name;
        }
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleOnInputChange = (input) => {
    const { name, value } = input.target;
    setUserData({ ...userData, [name]: value });
    console.log(name);
  };

  const handleMembershipChange = (event) => {
    setUserData({ ...userData, membership: event.target.value });
  };

  const handlePhoneNumberChange = (input) => {
    setUserData({ ...userData, phone: input });
  };

  const usertype = localStorage.getItem("usertype");
  console.log(usertype);

  const handleOnSubmitForm = async (e) => {
    e.preventDefault();
    try {
      console.log("userData inside onSubmit", userData);
      const token = localStorage.getItem("accessToken");
      const formData = new FormData();
      formData.append("image", imagePath || userData.image);
      formData.append("firstname", userData.firstname);
      formData.append("lastname", userData.lastname);
      formData.append("email", userData.email);
      // formData.append("phone", userData.phone);
      // formData.append("location", userData.location);
      // formData.append("organisation", userData.organisation);
      formData.append("address", userData.address);
      formData.append("membership", userData.membership);
      const response = await axios.put(
        `${window.env_url}/v1/users/${userData.id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json",
          },
        }
      );
      toast.success("Data saved successfully!");
      const updatedDetails = response.data.body[0];
      sessionStorage.removeItem("fullName");
      sessionStorage.removeItem("profPicture");
      sessionStorage.setItem(
        "fullName",
        `${updatedDetails?.firstname} ${updatedDetails?.lastname}`
      );
      sessionStorage.setItem("profPicture", updatedDetails?.image);
      const membership = updatedDetails?.membership;
      setCurrentMembership(membership)
      onClose();
      refetch();
      closeProfile();
    } catch (error) {
      console.log(error.message);
    }
  };

  if (isLoading) {
    return (
      <>
        <div className="formWrapper">
          <div className="formProfileImgWrapper">
            <div className="formProfileImg">
              <img src={profileImage} alt="Profile" className="profileImage" />
              <label htmlFor="imageInput">
                <img
                  src={ProfileSelectionIcon}
                  alt="Profile Selection"
                  className="overlayIcon"
                />
              </label>
              <input
                type="file"
                id="imageInput"
                accept="image/*"
                onChange={handleImageChange}
                style={{ display: "none" }}
              />
            </div>
            {/* <div className="profileSelectionIcon">
          <img src={ProfileSelectionIcon} alt="" />
         </div> */}
          </div>
          Loading.....
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="formWrapper">
          <div className="formProfileImgWrapper">
            <div className="formProfileImg">
              <img src={profileImage} alt="Profile" className="profileImage" />
              <label htmlFor="imageInput">
                <img
                  src={ProfileSelectionIcon}
                  alt="Profile Selection"
                  className="overlayIcon"
                />
              </label>
              <input
                type="file"
                id="imageInput"
                accept="image/*"
                onChange={handleImageChange}
                style={{ display: "none" }}
              />
            </div>
          </div>
          <form onSubmit={handleOnSubmitForm} action="" id="updateProf">
            <div className="formFlexClass">
              <div className="formInputWrapper">
                <label htmlFor="fName">First Name</label>
                <input
                  type="text"
                  name="firstname"
                  id="fName"
                  onChange={handleOnInputChange}
                  value={userData?.firstname}
                  required
                />
              </div>
              <div className="formInputWrapper">
                <label htmlFor="lName">Last Name</label>
                <input
                  type="text"
                  name="lastname"
                  id="lName"
                  onChange={handleOnInputChange}
                  value={userData?.lastname}
                  required
                />
              </div>
            </div>
            {/* mail and contact */}
            <div className="formFlexClass">
              <div className="formInputWrapper">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  onChange={handleOnInputChange}
                  value={userData?.email}
                  required
                  disabled
                />
              </div>
              <div className="formInputWrapper">
                <label htmlFor="phNum">Phone number</label>
                <PhoneNumberInput
                  onChange={handlePhoneNumberChange}
                  value={userData?.phone}
                />
              </div>
            </div>
            {/* address */}
            {!(usertype === "coach") && (
              <div className="formFlexClass">
                <div className="formInputWrapper fullMailAddress">
                  <label htmlFor="address">Full Mailing Address</label>
                  <textarea
                    name="address"
                    id="address"
                    onChange={handleOnInputChange}
                    value={userData?.address}
                    required
                  />
                </div>
              </div>
            )}
            {!(usertype === "coach") && (
              <div className="formFlexClass">
                <div className="formInputWrapper fullMailAddress">
                  <label
                    for="membership-options"
                    className="defaultLabelStyle"
                    // style="font-family: Nunito;"
                  >
                    Which Membership are you interested in?
                  </label>
                  <div
                    className="radioContainer"
                    style={{ display: "flex", gap: "6%", flexDirection: "row" }}
                  >
                    <label className="radioLabelStyle">
                      <input
                        type="radio"
                        name="membership"
                        value="investor_readiness_program"
                        checked={
                          userData.membership === "investor_readiness_program"
                        }
                        onChange={handleMembershipChange}
                      />
                      Investor Readiness Program
                    </label>
                    <label className="radioLabelStyle">
                      <input
                        type="radio"
                        name="membership"
                        value="private_office"
                        checked={userData.membership === "private_office"}
                        onChange={handleMembershipChange}
                      />
                      Office Space Private Office
                    </label>
                    <label className="radioLabelStyle">
                      <input
                        type="radio"
                        name="membership"
                        value="cubicle"
                        checked={userData.membership === "cubicle"}
                        onChange={handleMembershipChange}
                      />
                      Cubicle
                    </label>
                    <label className="radioLabelStyle">
                      <input
                        type="radio"
                        name="membership"
                        value="hot_desk"
                        checked={userData.membership === "hot_desk"}
                        onChange={handleMembershipChange}
                      />
                      Hot Desk
                    </label>
                    <label className="radioLabelStyle">
                      <input
                        type="radio"
                        name="membership"
                        value="virtual_membership"
                        checked={userData.membership === "virtual_membership"}
                        onChange={handleMembershipChange}
                      />
                      Virtual Membership
                    </label>
                  </div>
                </div>
              </div>
            )}
            <div className="buttonWrapper">
              <button type="submit">Save</button>
              <button onClick={onClose}>Cancel</button>
            </div>
          </form>
        </div>
      </>
    );
  }
}

export default ProfileForm;
