import { useEffect, useState } from "react";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import axios from "axios";
import "./CoachRequests.css";
import { Paginator } from "primereact/paginator";
import moment from "moment";
import CoachDetailsPage from "./CoachDetailsPage";

const CoachRequests = () => {
  const [requestsData, setRequestsData] = useState([]);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [refetch, setRefetch] = useState(false);

  const fetchSingleCoachBookings = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/coachbookings/byCoach`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setRequestsData(response.data.body[0] || []);
    } catch (error) {
      console.error(error?.message);
    }
  };

  useEffect(() => {
    fetchSingleCoachBookings();
  }, [refetch]);

  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const paginatedData = requestsData?.slice(first, first + rows);

  function formatTime(time) {
    if (!time) return "";
    return moment(time, "HH:mm:ss").format("hh:mm a");
  }

  function getStatusClassName(visibility) {
    switch (visibility) {
      case "accepted":
        return "acceptedStatus";
      case "rejected":
        return "rejectedStatus";
      default:
        return "pendingStatus";
    }
  }

  const viewHandle = (id) => {
    const item = requestsData?.filter((item) => item.id === id);
    setSelectedData(item);
    console.log("to get id of selected item", item);
    setDetailsOpen(true);
  };

  return (
    <div className="mr mr-root">
      <Navbar />
      <div className="mr-container">
        <Sidebar />
        <div className="mr-right-section coach-requests">
          <h2 className="coachReq header">Coach Requests</h2>
          {requestsData?.length > 0 ? (
            <>
              <div className="coachReqTableContainer">
                <table
                  className="bookingRequestTable coachRequests"
                  id="bookingDetailsId"
                >
                  <thead>
                    <tr>
                      <th>Customer Name</th>
                      <th>Check-In Date</th>
                      <th>Email ID</th>
                      <th>Check-In Time</th>
                      <th>Status</th>
                      <th>More Information</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paginatedData?.map((request) => {
                      return (
                        <tr key={request?.id}>
                          <td>
                            {request?.user
                              ? `${request?.user.firstname} ${request?.user.lastname}`
                              : "Loading..."}
                          </td>
                          <td>{request?.checkInDate}</td>
                          <td>{request?.coach?.email}</td>
                          <td>{formatTime(request?.checkInTime)}</td>
                          {/** gives time in the format  HH:MM:ss convert it into HH:MM am/pm*/}
                          <td>
                            <button
                              className={getStatusClassName(
                                request?.visibility
                              )}
                            >
                              {" "}
                              {request?.visibility === "accepted"
                                ? "Accepted"
                                : request?.visibility === "rejected"
                                ? "Rejected"
                                : "Pending"}
                            </button>
                          </td>
                          <td id="viewDetailsId">
                            <span
                              className="details-span"
                              onClick={() => {
                                viewHandle(request?.id);
                              }}
                            >
                              view details
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <Paginator
                first={first}
                rows={rows}
                totalRecords={requestsData?.length}
                // rowsPerPageOptions={[10, 20, 30]}
                onPageChange={onPageChange}
              />
            </>
          ) : (
            <div className="coachReq noShow">No Bookings requests found!</div>
          )}
        </div>
        {detailsOpen && (
          <CoachDetailsPage
            isOpen={detailsOpen}
            onClose={() => setDetailsOpen(false)}
            //onAccept={acceptHandle}

            detailsData={selectedData}
            // setRefreshState={setRefreshState}
            // bookId={id}
            fetch={refetch}
            refetch={setRefetch}
          />
        )}
      </div>
    </div>
  );
};

export default CoachRequests;
