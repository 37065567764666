import { createContext, useContext, useEffect, useState } from "react";
import { getMembership, token } from "../utils/utilityFunctions";

export const MembershipContext = createContext();

export const useMembership = () => useContext(MembershipContext);

export const MembershipProvider = ({ children }) => {
  const [currentMembership, setCurrentMembership] = useState(null);
  useEffect(() => {
    const fetchMembership = async () => {
      try {
        const membership = await getMembership(token);
        setCurrentMembership(membership);
      } catch (error) {
        console.error(error?.message);
      }
    };
    if (token) {
      fetchMembership();
    }
  }, [token]);

  const value = { currentMembership, setCurrentMembership };

  return (
    <MembershipContext.Provider value={value}>
      {children}
    </MembershipContext.Provider>
  );
};
