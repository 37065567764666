import { Calendar, momentLocalizer } from "react-big-calendar";
import "./CalSchedule.css";
import moment from "moment";
import SelectRoom from "../dropdown/SelectRoom";
import { token } from "../../utils/utilityFunctions";
import { useEffect, useState } from "react";
import { Tooltip, colors, styled, tooltipClasses } from "@mui/material";
import axios from "axios";

const CalSchedule = () => {
  const localizer = momentLocalizer(moment);
  const [rooms, setRooms] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState("");
  const [selectedMonth, setSelectedMonth] = useState(null);

  const [eventss, setEventss] = useState(null);
  const getAllRooms = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/rooms`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setRooms(response.data.body[0]);
    } catch (error) {
      console.error("Error fetching rooms");
    } finally {
      // setIsLoading(false);
    }
  };

  const getAllEventsByRoomId = async () => {
    try {
      console.log("selectedRoom Inside get All events", selectedRoom);
      const response = await fetch(
        `${window.env_url}/v1/meetingbookings/${selectedRoom}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      setEventss(data.body[0]);
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    getAllEventsByRoomId();
  }, [selectedRoom]);

  const formattedEvents = eventss
    ?.filter(
      (event) =>
        event?.visibility === "accepted" || event?.visibility === "pending"
      // ||
      // (event?.visibility === "rejected" && event?.userId === numUserId)
    )
    .map((event) => {
      const startDateTime = moment(
        `${event.checkInDate} ${event.checkInTime}`,
        "YYYY-MM-DD HH:mm:ss"
      );
      const endDateTime = moment(
        `${event.checkOutDate} ${event.checkOutTime}`,
        "YYYY-MM-DD HH:mm:ss"
      );

      console.log("event inside map", event);

      return {
        id: event.id,
        roomId: event.bookedRoomId,
        title: "", // You can customize this if you have specific titles
        start: startDateTime.toDate(), // Format the start datetime
        end: endDateTime.toDate(), // Format the end datetime
        facilities: event.facilities, // Assuming event.facilities is an array of facilities
        notes: event.notes,
        seats: event?.seats,
        // roomCapacity: roomSeats,
        isRescheduled: event?.isRescheduled,
        visibility: event?.visibility,
        userId: event?.userId,
      };
    });

  useEffect(() => {
    getAllRooms();
  }, []);

  const EventComponent = ({ event }) => {
    let visibilityColor;
    let textColor;

    console.log("event Inside Event component", event, rooms);

    switch (event?.visibility) {
      case "accepted":
        visibilityColor = "#265985";
        textColor = "white";
        break;
      case "pending":
        visibilityColor = "yellow";
        textColor = "black";
        break;
      case "rejected":
        visibilityColor = "red";
        textColor = "white";
        break;
      default:
        visibilityColor = "black"; // Default color if visibility is not specified or unknown
        break;
    }

    const eventStyle = {
      backgroundColor: visibilityColor,
      color: textColor,
    };

    const facilities = event?.facilities
      ? event?.facilities
      : "No facility available";
    const notes = event?.notes;

    const tooltipContent = `
     Facilities : ${facilities}, 
     Notes : ${notes}
      `;

    const room = rooms.find((ele) => event?.roomId === ele?.id);
    const roomName = room?.title?.split("|")[0] || "Unknown Room";

    console.log("roomName", roomName);

    return (
      <div style={eventStyle}>
        <Tooltip title={tooltipContent}>
          <div>{roomName}</div>
          <div style={{ textTransform: "capitalize" }}>
            Status : {event?.visibility}
          </div>
        </Tooltip>
      </div>
    );
  };

  return (
    <div className="calDiv">
      <div className="cal-wrapper scheduleCalendar" style={{ height: "auto" }}>
        <SelectRoom
          rooms={rooms}
          setEvents={setEventss}
          selectedRoom={selectedRoom}
          setSelectedRoom={setSelectedRoom}
          setSelectedMonth={setSelectedMonth}
        />
        <Calendar
          defaultDate={moment().toDate()}
          defaultView="month"
          events={formattedEvents}
          localizer={localizer}
          startAccessor="start"
          endAccessor="end"
          //   onEventDrop={onEventDrop}
          //   onEventResize={onEventResize}
          //   onView={(view) => handleViewChange(view)}
          //   onNavigate={(date, view) => handleNavigate(date, view)}
          //   resizable
          //   onSelectEvent={(event) => {
          //     if (event?.isUnavailable) {
          //       return;
          //     }
          //     setSelectedBooking(event);
          //   }}
          //   showMultiDayTimes
          //   views={["month", "week", "day"]}
          //   style={{ height: "100vh" }}
          //   dateCellWrapper={dateCellWrapper}
          components={{
            event: EventComponent,
            agenda: { event: EventComponent },
            week: { event: EventComponent },
            day: { event: EventComponent },
          }}
        />
      </div>
    </div>
  );
};

export default CalSchedule;
