import RCNavbar from "../nav/RCNavbar";
import CalSchedule from "./CalSchedule";

const RoomCalendar = () => {
  return (
    <>
      <RCNavbar />
      <CalSchedule />
    </>
  );
};

export default RoomCalendar;
