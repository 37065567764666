import React, { useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { useQuery } from "react-query";
import "./SelectRoom.css";
import { fetchAllData } from "../../utils/fetchData";
import axios from "axios";
import { token } from "../../utils/utilityFunctions";
import moment from "moment";

// Fetch all data utility
const SelectRoom = ({rooms, selectedRoom, setSelectedRoom, setEvents, setSelectedMonth}) => {
  const meetingRoomsEndPoint = "/v1/rooms";

  const [isLoading, setIsLoading] = useState(false);


  // useEffect(() => {
  //   getAllEventsByRoomId();
  // }, [selectedRoom]);

    useEffect(() => {
      const startDate = moment().startOf("month").format("YYYY-MM-DD");
      const endDate = moment().endOf("month").format("YYYY-MM-DD");
      setSelectedMonth({ startDate, endDate });
    }, []);

  // Handle room selection
  const handleChange = async (event) => {
    const roomId = event.target.value;
    console.log("rooMId", roomId);
    setSelectedRoom(roomId);
    try {
      const roomDetails = await fetchAllData(`/v1/rooms/${roomId}`);
      console.log("Selected Room Details: ", roomDetails);
    } catch (error) {
      console.error("Error fetching room details:", error);
    }
  };



  console.log("SelectedRoom", selectedRoom);


  
    

  return (
    <div className="select-container">
      <FormControl
        fullWidth
        disabled={isLoading}
        className="select-room-form-control"
      >
        <InputLabel id="room-select-label">Room</InputLabel>
        <Select
          labelId="room-select-label"
          id="room-select"
          value={selectedRoom}
          onChange={handleChange}
          input={<OutlinedInput label="Room" />}
          MenuProps={{
            MenuListProps: {
              sx: {
                maxHeight: "300px",
              },
            },
          }}
          sx={{
            fontFamily: " Nunito",
            fontSize: "16px",
          }}
        >
          {rooms.map((room) => (
            <MenuItem
              key={room.id}
              value={room.id}
              sx={{
                fontFamily: "Nunito",
                fontSize: "16px",
              }}
            >
              {room.title.split("|")[0]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default SelectRoom;
