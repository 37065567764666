import axios from "axios";
const apiURL = process.env.REACT_APP_API_URL;

const fetchAllData = async (endpoint) => {
  try {
    const token = localStorage.getItem("quickBookToken");
    //Fetching data
    // console.log("link",`${apiURL}${endpoint}`)
    const response = await axios.get(`${apiURL}${endpoint}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const data = response.data.body[0];

    if (endpoint.includes("/filterfacilities")) {
      return data;
    }

    return data;
  } catch (error) {
    console.error("Error fetching All data:", error);
  }
};

const fetchSingleData = async (endpoint) => {
  try {
    const token = localStorage.getItem("accessToken");

    //Fetching data
    console.log("link", `${apiURL}${endpoint}`);
    const response = await axios.get(`${apiURL}${endpoint}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const data = response.data.body[0];
    console.log(" meeting data", data);

    //Fetching image

    let imageResponse = "";
    if (data?.files.includes("|")) {
      imageResponse = await axios.get(
        `${apiURL}/v1/files/${data.files.split("|")[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: "arraybuffer",
        }
      );
    } else {
      imageResponse = await axios.get(
        `${apiURL}/v1/files/${data?.image || data.files}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: "arraybuffer",
        }
      );
    }

    const blob = new Blob([imageResponse.data], { type: "image/jpeg" });
    const url = URL.createObjectURL(blob);
    console.log("regex", url);
    data.imageUrl = url;

    return data;
  } catch (error) {
    console.error(`Error fetching ${endpoint} data:fetchsingle`, error);
  }
};

const fetchUserDetails = async () => {
  try {
    const token = localStorage.getItem("accessToken");
    const { data } = await axios.get(`${apiURL}/v1/users`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const userData = data.body[0];

    if (userData.image.includes("http")) {
      // const imageResponse = await axios.get(`${userData.image}`,
      // {
      //   responseType: 'arraybuffer',
      // });

      // const blob = new Blob([imageResponse.data],{ type: 'image/jpeg' })
      // const url = URL.createObjectURL(blob)
      userData.imageUrl = userData.image;
    } else {
      const imageResponse = await axios.get(
        `${apiURL}/v1/files/${userData.image}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: "arraybuffer",
        }
      );

      const blob = new Blob([imageResponse.data], { type: "image/jpeg" });
      const url = URL.createObjectURL(blob);
      userData.imageUrl = url;
    }

    return userData;
  } catch (error) {
    console.log(error);
  }
};

export { fetchAllData, fetchSingleData, fetchUserDetails };
